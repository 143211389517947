<template>
  <van-pull-refresh
    v-model="pullLoading"
    :disabled="loading"
    @refresh="getApplyInfo"
  >
    <div class="apply-info">
      <div class="banner">
        <div class="info">
          <div>{{ orderInfo.customerName }}</div>
          <div>{{ pro.productName }}</div>
        </div>
        <div class="apl-info">
          <div class="a-i-item">
            <div class="val">{{ orderInfo.parseApplyAmount }}</div>
            <div class="name">
              {{ +appStatus >= 110 ? "批复金额" : "申请金额" }}
            </div>
          </div>
          <div class="a-i-item">
            <div class="val">{{ orderInfo.applyTerm }}</div>
            <div class="name">
              {{ +appStatus >= 110 ? "批复期数" : "申请期数" }}
            </div>
          </div>
        </div>
      </div>
      <div class="apply-detail-content">
        <div class="page-block">
          <div class="info-title">申请人信息</div>
          <van-cell
            v-for="(item, i) in applyInfo"
            :title="item.name"
            :key="i"
            :value="item.value"
          />
        </div>

        <div class="page-block replenish">
          <div class="info-title">补充信息</div>
          <!-- 目前所有入口都可点击 -->
          <div v-for="(item, i) in replenishInfo" :key="i">
            <!-- v-if="item.show" -->
            <van-cell
              v-if="item.show"
              :title="item.name"
              :is-link="item.canClick"
              @click="itemClick(item)"
            >
              <template #default>
                <span :class="item.status ? 'suc' : 'usual'">{{
                  item.status ? "已完成" : item.unFillText || "去完成"
                }}</span>
              </template>
            </van-cell>
          </div>
        </div>
      </div>

      <div v-if="['-3', '60'].includes(appStatus)" class="page-btn btns">
        <van-button type="info" size="small" @click="applyFn"
          >立即申请</van-button
        >
      </div>
      <IdentityOCR
        v-model="ocrShow"
        :prePlatOrderNo="prePlatOrderNo"
        @success="getApplyInfo"
      />
    </div>
  </van-pull-refresh>
</template>
<script>
import { getLastOrder } from "@/api/login";
import {
  orderDetailInfo,
  submitApplyReq,
  reSubmitApplyReq,
  authorizationUrl
} from "@/api/apply";
import { stringHypose } from "@/utils/tool";
import { Notify } from "vant";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IdentityOCR: () => import("../components/IdentityOCR.vue")
  },
  data() {
    return {
      pullLoading: false,
      bizNo: "",
      loading: false,
      user: {}, // 用户信息
      type: "1", // 1当前进入是从登录后进入；2当前进入是从列表进入
      pro: {}, // 产品信息
      ocrShow: false,
      prePlatOrderNo: "", // 预进件订单号
      appStatus: "", // 订单状态
      orderInfo: {},
      timeingRef: null
      // replenishInfo: [],
      // applyInfo: [
      // ]
    };
  },
  computed: {
    applyInfo() {
      const { customerName, customerPhone, certificateNo } = this.orderInfo;
      return [
        {
          name: "申请人",
          value: customerName
        },
        {
          name: "手机号",
          value: stringHypose(customerPhone)
        },
        {
          name: "身份证号",
          value: stringHypose(certificateNo, [6, 14])
        }
      ];
    },
    replenishInfo() {
      const {
        appStatus,
        certificationStatus, // 身份认证状态
        personalInformationStatus, // 个人信息状态
        workInformationStatus, //工作信息状态
        vehicleInformationStatus, // 车辆信息状态
        incomingMaterialStatus, //材料状态
        guarantorStatus, // 是否存在担保人（可能不存在）
        guarantorBindStatus, // 担保人是否已经绑定申请人
        guarantorRejectStatus, //  1：待提交给资方 2：担保人已提交给资方 3：资方已拒绝担保人
        bankBindStatus, // 绑卡状态
        entrustedSignStatus, // 委托授权签署状态
        livingStatus, // 活体状态
        processNo // 所退回节点位置 只有 2：资方预审； 4：资方授信； 8：资方用信
      } = this.orderInfo;
      return [
        {
          name: "身份认证",
          show: true, // 必定展示
          status: certificationStatus,
          canClick: !certificationStatus,
          click: () => {
            if (certificationStatus) return;
            Notify.clear();
            this.destroyTimingRefresh();
            this.ocrShow = true;
          }
        },
        {
          name: "个人信息",
          status: personalInformationStatus && workInformationStatus,
          show:
            // 工作、个人信息已经完成就必定展示；或者录单（appStatus == 60）的时候展示
            (personalInformationStatus && workInformationStatus) ||
            +appStatus == "60",
          canClick: !personalInformationStatus || !workInformationStatus,
          click: () => {
            if (personalInformationStatus && workInformationStatus) return;
            if (!personalInformationStatus) this.$router.push("/personal-info");
            else if (!workInformationStatus) this.$router.push("/works-info");
          }
        },
        {
          name: "车辆信息",
          status: vehicleInformationStatus,
          // 车辆信息不全/被驳回的时候展示；或者录单（appStatus == 60）的时候展示
          show: vehicleInformationStatus || appStatus == "60",
          canClick: !vehicleInformationStatus,
          click: () => {
            if (vehicleInformationStatus) return;
            this.$router.push("/vehicle-info");
          }
        },
        // 风控预审通过展示 4 8
        {
          name: "进件材料",
          status: incomingMaterialStatus,
          // 可退回节点 4：资方授信； 8：资方用信
          show:
            appStatus == "60" || // 录单状态 或者 退回状态，且从 4/8 退回 或者 已完成
            (appStatus == "-3" && [4, 8].includes(+processNo)) ||
            incomingMaterialStatus,
          canClick: !incomingMaterialStatus,
          click: () => {
            if (incomingMaterialStatus) return;
            if (appStatus == "60") this.$router.push("/materials-info");
            else {
              // Notify.clear();
              this.$router.push("/materials-info?isReturn=1");
            }
          }
        },
        {
          name: "担保人信息",
          status: guarantorStatus && guarantorBindStatus,
          unFillText: "待完成",
          // 存在担保人并且担保人没有与申请人绑定、订单状态60展示
          // 存在担保人，已完成暂时
          show:
            (guarantorStatus && !guarantorBindStatus && appStatus == "60") ||
            (guarantorStatus && guarantorBindStatus)
        },
        {
          name: "担保人",
          status: [1, 2].includes(+guarantorRejectStatus),
          canClick: guarantorRejectStatus == "3",
          unFillText: "待补充",
          // 只在存在担保人且订单状态为退回展示，并且退回节点 processNo == 2 ；guarantorRejectStatus 为1或3
          show:
            appStatus == "-3" &&
            guarantorStatus &&
            [1, 3].includes(+guarantorRejectStatus) &&
            processNo == "2",
          click: () => {
            if (guarantorRejectStatus != "3") {
              return;
            }
            const redirect = encodeURIComponent(this.$route.fullPath);
            this.$router.push({
              path: "/guarantor",
              query: {
                redirect,
                mode: "2"
              }
            });
          }
        },
        {
          name: "还款卡绑定",
          status: bankBindStatus,
          // 平台授信成功展示
          show: appStatus == "110",
          click: () => {
            this.$router.push("/bindcard-1");
          }
        },
        {
          name: "活体检测",
          status: false,
          // 活体检测不通过展示
          // 可退回节点 4：资方授信
          show: !livingStatus && appStatus == "-3" && processNo == "4",
          canClick: !livingStatus,
          click: () => {
            if (livingStatus) return;
            this.$router.push("/identity-portrait-face?isReturn=1");
          }
        },
        // 委托签约书
        {
          name: "授权书签署",
          status: false,
          unFillText: "待完成",
          show: bankBindStatus && !entrustedSignStatus,
          click: () => {
            this.getAuthUrl();
          }
        }
      ];
    }
  },
  created() {
    const { bizNo } = this.$route.query;
    // 存在订单号，就是订单列表进入
    this.type == bizNo ? "2" : "1";
    this.bizNo = bizNo;

    // 用户信息
    const user = this.getUserInfo();
    this.user = user || {};
    // 产品信息
    const pro = this.getProductInfo();
    this.pro = pro || {};
    if (this.type == "1") {
      // 不存在订单编号，就是登陆进入或者其他方式进入
      const data = this.getUserOrderInfo();
      // 如果缓存中不存在bizNo
      if (!data || !data.bizNo) this.getLastOrder();
      else {
        this.bizNo = data.bizNo;
        this.prePlatOrderNo = data.prePlatOrderNo;
        this.getApplyInfo();
      }
    } else {
      this.getApplyInfo();
    }
    window.devToolSetOrderInfo = this.devToolSetOrderInfo;
  },
  destroyed() {
    this.destroyTimingRefresh();
    Notify.clear();
  },
  methods: {
    ...mapGetters(["getProductInfo", "getUserOrderInfo", "getUserInfo"]),
    ...mapActions(["setUserOrderInfo"]),
    itemClick(item) {
      if (item.click) item.click();
    },
    devToolSetOrderInfo(data, key) {
      this.$set(this.orderInfo, key, data);
      console.log(this.orderInfo);
      this.$forceUpdate();
    },
    // 申请信息
    async getApplyInfo() {
      try {
        this.loading = true;
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await orderDetailInfo(this.bizNo);
        let parseApplyAmount = 0;
        if (data.applyAmount) {
          parseApplyAmount = (data.applyAmount / 10000).toFixed(1);
        }
        data.parseApplyAmount = parseApplyAmount;
        this.$set(this, "orderInfo", data);
        this.appStatus = data.appStatus || "";

        if (data && data.description && +data.appStatus < 0) {
          Notify({
            type: "danger",
            message: data.description,
            duration: 0
          });
        } else {
          Notify.clear();
        }
        this.$toast.clear();
        // 成功后设定下一次请求
        this.createTimingRefresh();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
        this.pullLoading = false;
      }
    },
    createTimingRefresh() {
      if (this.timeingRef) return;
      this.timeingRef = setTimeout(() => {
        this.getApplyInfo();
        this.timeingRef = null;
      }, 10 * 1000);
    },
    destroyTimingRefresh() {
      if (this.timeingRef) window.clearTimeout(this.timeingRef);
    },
    // 获取最近订单
    async getLastOrder() {
      try {
        // const info = this.getProductInfo();
        this.loading = true;
        const { merchantNo, productNo } = this.pro;
        const { data } = await getLastOrder(productNo, merchantNo);

        this.setUserOrderInfo(data);
        this.bizNo = data.bizNo || "";
        this.prePlatOrderNo = data.prePlatOrderNo;
        this.loading = false;
        this.getApplyInfo();
      } catch (error) {
        this.$errMsg(error);
        this.loading = false;
      }
    },
    applyChecked() {
      const {
        appStatus,
        certificationStatus,
        personalInformationStatus,
        workInformationStatus,
        vehicleInformationStatus,
        incomingMaterialStatus,
        guarantorStatus,
        guarantorBindStatus,
        guarantorRejectStatus,
        livingStatus,
        processNo
      } = this.orderInfo;
      // 是-3 就是退回
      const isReturn = appStatus == "-3";
      if (!isReturn) {
        if (!certificationStatus) throw "请先完善身份认证信息";
        if (!personalInformationStatus || !workInformationStatus)
          throw "请先完善个人信息";
        if (!vehicleInformationStatus) throw "请先完善车辆信息";
        if (!incomingMaterialStatus) throw "请先完善材料信息";
        if (guarantorStatus && !guarantorBindStatus) throw "请先完善担保人绑定";
        const showGua =
          appStatus == "-3" && guarantorStatus && processNo == "2";
        if (showGua && guarantorRejectStatus == 3) throw "请先完善担保人信息";
        if (!livingStatus) throw "请先进行活体认证";
      } else {
        if (!certificationStatus) throw "请先完善身份认证信息";
        if (!incomingMaterialStatus && [4, 8].includes(+processNo))
          throw "请补充材料信息";
        const showGua =
          appStatus == "-3" && guarantorStatus && processNo == "2";
        if (showGua && guarantorRejectStatus == 3 && processNo == "2")
          throw "请补充担保人信息";
        if (!livingStatus && processNo == "4") throw "请重新进行活体认证";
      }
    },
    // 发起申请提交
    async applyFn() {
      try {
        this.destroyTimingRefresh();
        await this.applyChecked();
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true,
          duration: 0
        });
        const submitFn =
          this.appStatus == "-3" ? reSubmitApplyReq : submitApplyReq;
        await submitFn({
          bizNo: this.bizNo
        });
        this.$toast.success("提交成功，请耐心等候");

        this.getApplyInfo();
        // this.createTimingRefresh();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 获取url
    async getAuthUrl() {
      try {
        if (!this.bizNo) throw "订单编号错误";
        this.$toast.loading({
          message: "加载授权书...",
          forbidClick: true
        });
        const res = await authorizationUrl(this.bizNo);
        const urlReg =
          /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;
        if (res.data && urlReg.test(res.data))
          window.location.href = res.data || "";
        else throw "授权书地址错误";
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.apply-info {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .banner {
    width: 100%;
    height: 286px;
    background: url("/images/index/背景.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 24px;
    box-sizing: border-box;
    color: #fff;
    font-weight: bold;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      margin-top: 60px;
    }
    .apl-info {
      margin: 0 auto;
      width: 80vw;
      display: flex;
      margin-top: 48px;
      align-items: center;
      justify-content: center;
      .a-i-item {
        width: 50%;
        text-align: center;
        .val {
          font-size: 58px;
          &::after {
            content: "万元";
            font-size: 24px;
            margin-left: 10px;
          }
        }
        &:last-child .val::after {
          content: "期";
        }
        .name {
          font-size: 24px;
        }
      }
    }
  }
  .apply-detail-content {
    z-index: 1;
    flex: 1;
    overflow-y: auto;
    .page-block {
      overflow: hidden;
      margin: 40px 32px;
      border-radius: 8px;
      position: relative;
      padding-top: 40px;
      box-shadow: 0 0 10px #aaa6;
      .info-title {
        width: 368px;
        height: 64px;
        margin-top: -48px;
        background: url("/images/login/title@2x.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 64px;
        font-size: 32px;
        font-weight: 600;
        margin-left: calc((100% - 368px) / 2);
        color: #ffffff;
      }
      .van-cell {
        .suc {
          color: #52c41a;
        }
        .warn {
          color: #faad14;
        }
        .usual {
          color: #1890ff;
        }
      }
    }
  }
  .btns {
    box-shadow: 0 -10px 10px #f6f6f6;
    z-index: 10;
    .van-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
