import { get, post } from "@/utils/https";
import qs from "qs";
// 登录验证码
export function smsLgcode(mobile, params) {
  return get(`/api/v1/common/cus/auth/sms/login/${mobile}`, params);
}
// 注册(登录)
export function login(data) {
  let authToken = window.btoa(
    `${process.env.VUE_APP_AUTH_CLIENT_ID}:${process.env.VUE_APP_AUTH_CLIENT_SECRET}`
  );
  let headrC = {
    noLogin: true,
    "content-type": "application/x-www-form-urlencoded",
    platform: process.env.VUE_APP_AUTH_PLATFORM,
    Authorization: "Basic " + authToken
  };
  let userData = qs.stringify({
    grant_type: "sms",
    scope: "userProfile",
    ...data
  });
  return post(`/login/sms/token`, userData, null, headrC);
}
// 产品信息
export function productInfos(productNo) {
  if (!productNo) throw "缺少productNo";
  return get(`/api/v1/common/cus/infra/common/${productNo}/product`);
}
// 登录以后获取用户信息
export function getUserInfo() {
  return get(`/api/v1/common/saas/app/offline/apply/login/customer`);
}
// 查询最近订单 / 生成预进件订单
export function getLastOrder(applyProductNo, merchantNo) {
  return get(
    `/api/v1/common/cus/apply/wjf/apply/${applyProductNo}/${merchantNo}/latest`
  );
}
